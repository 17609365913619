import React, { createContext, useContext, useState, useEffect } from "react"

const DarkModeContext = createContext()

const LIGHT_MODE = "light"
// Tailwind requires this exact string to work with its "class" strategy
const DARK_MODE = "dark"
const PREFERS_DARK = "(prefers-color-scheme: dark)"

// A context provider that respects changes in the user's system settings and a manual toggle.
export const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false)

  // Function to apply the theme to the HTML body and our component state
  const applyTheme = isDarkMode => {
    if (isDarkMode) {
      document.body.classList.add(DARK_MODE)
      document.body.classList.remove(LIGHT_MODE)
    } else {
      document.body.classList.remove(DARK_MODE)
      document.body.classList.add(LIGHT_MODE)
    }
    setDarkMode(isDarkMode)
  }

  // On mount, check system preference and listen for changes
  useEffect(() => {
    const systemPrefersDark = window.matchMedia(PREFERS_DARK).matches
    applyTheme(systemPrefersDark)

    const handleSystemChange = event => {
      applyTheme(event.matches)
    }

    const mediaQuery = window.matchMedia(PREFERS_DARK)
    mediaQuery.addEventListener("change", handleSystemChange)

    // Cleanup listener on unmount
    return () => {
      mediaQuery.removeEventListener("change", handleSystemChange)
    }
  }, [])

  // Toggle dark mode manually
  const toggleDarkMode = () => {
    applyTheme(!darkMode)
  }

  return (
    <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  )
}

// Custom hook for consuming the context
export const useDarkMode = () => {
  const context = useContext(DarkModeContext)

  // Return fallback if window is undefined (SSR)
  if (typeof window === "undefined") {
    return { darkMode: false, setDarkMode: () => {} }
  }

  if (!context) {
    throw new Error("useDarkMode must be used within a DarkModeProvider")
  }

  return context
}
