import React from "react"
import { ConsentProvider } from "./src/context/consent"
import { DarkModeProvider } from "./src/context/darkMode"

import "./src/styles/global.css"

// Prism.js syntax highlighting
import "prismjs/themes/prism-tomorrow.css"

import "@fontsource/montserrat/400.css" // Regular
import "@fontsource/montserrat/500.css" // Medium
import "@fontsource/montserrat/700.css" // Bold
import "@fontsource/roboto/400.css" // Regular
import "@fontsource/roboto/500.css" // Medium
import "@fontsource/roboto/700.css" // Bold

export const wrapRootElement = ({ element }) => (
  <ConsentProvider>
    <DarkModeProvider>{element}</DarkModeProvider>
  </ConsentProvider>
)
